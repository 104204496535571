import React from "react"
import { Link, navigate } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params";
import { gql, useQuery } from '@apollo/client'
import Seo from "../components/seo"


const AddToCart = () => {
  const [bookId] = useQueryParam("bookId", StringParam);
  const [pub] = useQueryParam("pub", StringParam);
  const queryParams = {
    id: bookId,
  };

  const { loading, error, data } = useQuery(GET_BOOK, {
    variables: queryParams,
  });

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }

  if (error || data === void 0) {
    navigate("/404")
    return null;
  }

  const { book: { title, BookDependentInformation: { size, shipping, price, page, issuedate, memberprice, image: { guid } } } } = data;



  // guidの末尾からファイル名を取得する
  const fileName = guid.split('/').slice(-1)[0];
  // publicURLを生成
  const publicURL = `/static/${pub}/${fileName}`;

  return (
    <>
      <Seo title="出版・刊行物"></Seo>
      <div id="pageTitle">
        <div className="pageTitle_text">
          出版・刊行物
        </div>
      </div>
      <div className="breadClumb">
        <ul>
          <li><Link to="/">HOME</Link></li>
          <li><Link to="/publication">出版・刊行物</Link></li>
          <li>{title}</li>
        </ul>
      </div>


      <div id="main">
        <div className="contents">
          <section className="contents_block">
            <h1 className="header01">{title}</h1>
            <div className="inner">
              <div className="book_box">
                <div className="book_img"><img src={publicURL} alt="" className="imgFit" /></div>
                <div className="book_info">
                  <table className="tableStyle1">
                    <tbody>
                      <tr>
                        <th>サイズ</th>
                        <td>{size}</td>
                      </tr>
                      <tr>
                        <th>ページ</th>
                        <td>{page}</td>
                      </tr>
                      <tr>
                        <th>会員特価</th>
                        <td>{String(memberprice).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円（税込）</td>
                      </tr>
                      <tr>
                        <th>定価</th>
                        <td>{String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円（税込）</td>
                      </tr>
                      <tr>
                        <th>送料</th>
                        <td>{String(shipping).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}円</td>
                      </tr>
                      <tr>
                        <th>発行</th>
                        <td>{issuedate}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="book_link">
                  <p className="mb50">左記の書籍をカートに追加しました。</p>
                  <div className="mb50">
                    <Link to="/orderLogin" className="btn_rounded_topLogin">注文手続きを行う</Link>
                  </div>
                  <div className="mb50">
                    <Link to="/publication" className="btn_rounded">他の図書も注文する</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

const GET_BOOK = gql`
  query ($id: ID!){
    book (id: $id){
      id
      title
      BookDependentInformation {
        haserrata
        size
        shipping
        price
        page
        issuedate
        memberprice
        image {
          guid
        }
      }
    }
  }
`

export default AddToCart